
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    mixins: [cms, playGames],
    computed: {
        ...mapGetters({
            promotionGetter: 'cms/promotion',
        }),
        promotion() {
            return this.promotionGetter(this.$config.THEME)
        },
        promotionContent() {
            const chunkSize = 6
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.promotion?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(this.promotion?.items).slice(
                    i,
                    i + chunkSize
                )
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-promotion-next').click()
        },
    },
}
